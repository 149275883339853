import { processBigFive } from "../handlers/bigFiveHandler";

export function createDataPointProcessor(
  currentDate,
  compareDate,
  emotion,
  currentDict,
  compareDict,
  impactfulActions,
  filteredData,
  studentEmotionData,
  bigFiveFilteredData,
  selectedTimes,
  selectedSubOptions,
  helpfulFilter,
  tooltipCounts
) {
  return (datapoint, student) => {
    let datapointTimestamp = new Date(datapoint.date).valueOf();
    if (datapointTimestamp >= currentDate.valueOf()) {
      currentDict[datapoint.emotion] =
        (currentDict[datapoint.emotion] || 0) + 1;
      // Update student emotion data
      if (!studentEmotionData[student]) {
        studentEmotionData[student] = { count: 0, total: 0 };
      }
      // handle emotions data
      studentEmotionData[student].total++;
      if (
        datapoint.emotion === emotion ||
        emotion === "all emotions"
      ) {
        studentEmotionData[student].count++;
        filteredData.push(datapoint);
        // if (datapoint.helpful) {

        // }
      }
      tooltipCounts[datapoint.emotion][student] = (tooltipCounts[datapoint.emotion][student] || 0) + 1;
      // add 3 emotions data here as well 
      impactfulActions["action"][datapoint.action] = (impactfulActions["action"][datapoint.action] || 0) + 1;
      impactfulActions["emotion"][datapoint.emotion] = (impactfulActions["emotion"][datapoint.emotion] || 0) + 1;
      processBigFive(
        selectedTimes,
        selectedSubOptions,
        helpfulFilter,
        bigFiveFilteredData,
        datapoint,
        currentDate,
        student
      );
    } else if (datapointTimestamp >= compareDate.valueOf()) {
      compareDict[datapoint.emotion] =
        (compareDict[datapoint.emotion] || 0) + 1;
    }
  };
}
