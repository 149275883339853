// import { getSuperadminData } from "./getSuperadminData"; // Assuming you have a separate file for superadmin data
// import { getAdminData } from "./getAdminData"; // Assuming you have a separate file for admin data
// import { getTeacherData } from "./getTeacherData"; // Assuming you have a separate file for teacher data

import { ProcessDataChange } from "./ProcessDataChange";
import { get123WellnessData } from "./get123WellnessData";
import { getAdminData } from "./getAdminData";
import { getSuperadminData } from "./getSuperAdminData";
import { getTeacherData } from "./getTeacherData";

export const fetchData = async (state, realmUser, user) => {
  try {
    const data = await getAllData(state, realmUser, user);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

async function getAllData(state, realmUser, user) {
  switch (user.user.user) {
    case "123wellness":
      return await get123WellnessData(state, realmUser);
    case "superadmin":
      return await getSuperadminData(state, realmUser, user);
    case "admin":
      return await getAdminData(state, realmUser, user);
    case "teacher":
      return await getTeacherData(state, realmUser, user);
    default:
      return {
        districts: {},
        allWellnessPoints: [],
        allStudents: [],
        studentData: {},
        currentDictionary: initEmotionDictionary(),
        compareDictionary: initEmotionDictionary(),
        loading: false,
        counter: 0,
      };
  }
}

function initEmotionDictionary() {
  return {
    sad: 0,
    tired: 0,
    lonely: 0,
    happy: 0,
    grateful: 0,
    calm: 0,
    stressed: 0,
    scared: 0,
    angry: 0,
  };
}
