import React from "react";
import "./MyNextSteps.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookOpen,
  faHeart,
  faTrophy,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import HelpfulnessItemsDislay from "./HelpfulnessItemsDislay";
import EmotionsFeltDisplay from "./EmotionsFeltDisplay";

const MyNextSteps = ({
  filteredNextStepsData,
  impactfulActions,
  state,
  userRole,
  adultActionImages,
}) => {
  const topActions = impactfulActions?.action || {};
  const topEmotions = impactfulActions?.emotion || {};

  const getWellnessPracticesText = () => {
    // Check if state and userRole are defined
    if (!state || !userRole) {
      return "These are your top 3 helpful wellness practices. How might you incorporate these more in your daily life?";
    }

    let name =
      state.studentEmailToNameMap && state.chosenStudent
        ? state.studentEmailToNameMap[state.chosenStudent] ||
          state.chosenStudent
        : "the student";

    switch (userRole) {
      case "123wellness":
        return `These are the top wellness practices for ${
          state.chosenSchool || "the school"
        } in ${state.chosenDistrict || "the district"} in the last ${
          state.chosenTime || "period"
        }. How might you add more of these practices to your lesson plans?`;
      case "superadmin":
        return `These are the top wellness practices for ${name} in ${
          state.chosenClass || "the class"
        } in ${state.chosenSchool || "the school"} in the last ${
          state.chosenTime || "period"
        }. How might you add more of these practices to your lesson plans?`;
      case "admin":
      case "teacher":
        return `These are the top wellness practices for ${name} in ${
          state.chosenClass || "the class"
        } in the last ${
          state.chosenTime || "period"
        }. How might you add more of these practices to your lesson plans?`;
      default:
        return "These are your top 3 helpful wellness practices. How might you incorporate these more in your daily life?";
    }
  };

  return (
    <div className="next-steps-container">
      <div className="three-ways-subheader">
        3 Ways to Help Build Wellness
        <span className="icon-circle header-icon-circle">
          <FontAwesomeIcon className="header-icon" icon={faBookOpen} />
        </span>
        <span className="icon-circle header-icon-circle">
          <FontAwesomeIcon className="header-icon" icon={faHeart} />
        </span>
        <span className="icon-circle header-icon-circle">
          <FontAwesomeIcon className="header-icon" icon={faTrophy} />
        </span>
      </div>
      <div id="steps">
        <div className="step-container">
          <div className="icon-column">
            <span className="icon-circle">
              <FontAwesomeIcon className="fa-icon" icon={faBookOpen} />
            </span>
          </div>
          <div className="text-column">
            <h2 className="step-header">PRACTICE WELLNESS</h2>
            <p className="step-content">{getWellnessPracticesText()}</p>
            <HelpfulnessItemsDislay
              filteredNextStepsData={filteredNextStepsData}
              topActions={topActions}
              adultActionImages={adultActionImages}
            />
          </div>
        </div>

        <div className="step-container">
          <div className="icon-column">
            <span className="icon-circle">
              <FontAwesomeIcon className="fa-icon" icon={faHeart} />
            </span>
          </div>
          <div className="text-column">
            <h2 className="step-header">BUILD ON YOUR EMOTIONS</h2>
            {!state || !userRole ? (
              <div className="step-content">
                How might you continue to build strong relationships with your
                peers knowing that these are the top three emotions you have
                been feeling:
              </div>
            ) : (
              <div className="step-content">
                How might you continue to build strong relationships with your
                students knowing that these are the top three emotions they have
                been feeling:
              </div>
            )}
            
            <EmotionsFeltDisplay
              filteredNextStepsData={filteredNextStepsData}
              topEmotions={topEmotions}
            />
          </div>
        </div>

        <div className="step-container">
          <div className="icon-column">
            <span className="icon-circle">
              <FontAwesomeIcon className="fa-icon" icon={faTrophy} />
            </span>
          </div>
          <div className="text-column">
            <h2 className="step-header">CELEBRATE & REFLECT</h2>
            <p className="step-content">
              You're doing great! How might you celebrate your wellness success
              and continue building?
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyNextSteps;
