import React from "react";
import "./EmotionsLog.css";
import { FaExclamationTriangle, FaInfoCircle } from "react-icons/fa";
import { ChooseAnEmotion, NoData } from "./AlertComponents";

const EmotionsLogDisplay = ({
  studentEmotionData,
  chosenEmotion,
  chosenColor,
  studentEmailToNameMap
}) => {

  const sortedStudentData = studentEmotionData.sort(
    (a, b) => b.percent - a.percent
  );

  if (sortedStudentData.length === 0) {
    return (
      <NoData/>
    )
  }

  return (
    <div
      className="emotions-log-display"
      style={{ backgroundColor: chosenColor }}
    >
      <div className="student-grid">
        {sortedStudentData.map((student, index) => {
            let name = studentEmailToNameMap[student.name] ? studentEmailToNameMap[student.name]: student.name;
            return (
            
          <div key={index} className="student-item">
            <div className="student-name">{name}</div>
            <div className="student-data">
              Felt {chosenEmotion} {student.count} times (
              {student.percent.toFixed(2)}%)
            </div>
          </div>
        )})}
      </div>
    </div>
  );
};

export default EmotionsLogDisplay;
