import { BSON } from "realm-web";
import {
  loadSchool,
  loadClasses,
  loadAllClassesFromSchools,
  loadProfilesFromIds,
  loadProfiles,
  loadWellnesses,
  loadDistrictFromCleverId,
  loadDistrict,
  loadClassesFromCleverSchools,
  fetchSchoolIDsByNames,
  fetchSchoolsInfoByID,
  fetchSchoolsInfoByCleverId,
  loadAllDistricts,
  loadAllSchools,
  loadProfiles2,
  loadAllClassesFromSchool,
  loadSchoolsFromCleverId,
} from "../../../realm/graphqlQueries";
import { runPaginatedQuery } from "../../../realm/runPaginatedQuery";

export async function loadUserDistrict(user, realmUser) {
  // return user.user.cleverId
  //   ? await loadDistrictFromCleverId(user.user.district, realmUser)
  //   : await loadDistrict(user.user.district, realmUser);
  let realmResp;
     realmResp = await loadDistrictFromCleverId(
      user.user.district,
      realmUser
    );
    if (realmResp.district == null){
      realmResp = await loadDistrict(user.user.district,
      realmUser)
    }
    //console.log(realmResp)
    return realmResp 
}
// LOOpin through all schools instead of just the ones that the user is in need to fix this
export async function loadClassData(user, realmUser, userDistrict) {
  let classRef;
  const districts = {};
  let schools = (await loadSchoolsFromCleverId(userDistrict.district.cleverId,realmUser));  
  if (schools)
    schools = schools.schools
  else{
    schools = [{name:user.user.school}]
  }
  //console.log(user.user.schools)
  if (user.user.cleverId) {
    //console.log(schools);
    if (user.user.user === "admin") {
      classRef = await loadAllClassesFromSchools(user.user.schools, realmUser);
      console.log(classRef);
      districts[userDistrict.district.name] = {};
      user.user.schools.forEach((curSchool) => {
      const matchingSchool = schools.find(school => school.cleverId === curSchool)
      districts[userDistrict.district.name][matchingSchool.name] = {};
    });
    } else {
      classRef = await loadClassesFromCleverSchools(
        user.user.schools,
        user.user.class,
        realmUser
      );
      districts[userDistrict.district.name] = {};
      user.user.schools.forEach((curSchool) => {
      const matchingSchool = schools.find(school => school.cleverId === curSchool)
      districts[userDistrict.district.name][matchingSchool.name] = {};
    });
    }
    
  } else {
    if (user.user.user === "admin") {
      //console.log(user.user.school)

      classRef = await loadAllClassesFromSchool(user.user.school, realmUser);
      districts[userDistrict.district.name] = {};

        districts[userDistrict.district.name][user.user.school] = {};

      
    } else {
      classRef = await loadClasses(
        user.user.school,
        user.user.class,
        realmUser
      );
      districts[userDistrict.district.name] = {
        [user.user.school]: {},
      };
    }
  }

    // const school = cls.school || user.user.school;
    user.user.schools.forEach((curSchool) => {
      const matchingSchool = schools.find(school => school.cleverId === curSchool)

      if (!districts[userDistrict.district.name][matchingSchool.name]) {
        districts[userDistrict.district.name][matchingSchool.name] = [];
      }
      classRef.classes.forEach((cls) => {
      // districts[userDistrict.district.name][school.name] = {};

      if (!districts[userDistrict.district.name][matchingSchool.name][cls.name]) {
        districts[userDistrict.district.name][matchingSchool.name][cls.name] = [];
      }
      districts[userDistrict.district.name][matchingSchool.name][cls.name] =
        cls.students;
    });
    
  });
  //console.log(districts);

  return [classRef, districts];
}

export async function loadStudentData(user, allStudents, realmUser) {
  const studentProfiles = user.user.cleverId
    ? await loadProfilesFromIds(allStudents, realmUser)
    : await loadProfiles(allStudents, realmUser);

  const { idToEmail: studentIdToEmailMap, emailToName: studentEmailToNameMap } =
    createStudentMaps(studentProfiles.users);

  return { studentProfiles, studentIdToEmailMap, studentEmailToNameMap };
}

export async function getWellnessData(allStudentIDs, realmUser) {
  // TODO: move this away from old query structure (can just pass a list
  //       of values)
  if (allStudentIDs.length === 0 ) {
    return { wellnesses: [] };
  }

  let wellnessRef = {
    wellnesses: await runPaginatedQuery(
      realmUser,
      "wellness",
      {
        regex: {
          path: "userID",
          allowAnalyzedField: true,
          query: allStudentIDs.join("|"),
        },
      },
      null,
      null,
      { date: 1 }
    ),
  };

  wellnessRef.wellnesses.forEach((datapoint) => {
    datapoint.date = Date.parse(datapoint.date);
  });
  return wellnessRef;
}

export async function loadSchoolsInfo(user, userDistrict, realmUser) {
  if (!user.user.cleverId) {
    const schoolIDs = await fetchSchoolIDsByNames(
      userDistrict.district.schools,
      realmUser
    );
    return fetchSchoolsInfoByID(schoolIDs, realmUser);
  } else {
    return fetchSchoolsInfoByCleverId(userDistrict.district.schools, realmUser);
  }
}

export async function loadAllStudents(user, realmUser) {
  const allStudentsRef = await loadProfiles2(
    { district: user.user.district, user: "student" },
    realmUser
  );
  return allStudentsRef.users;
}

export async function load123WellnessData(realmUser) {
  return Promise.all([
    loadAllDistricts(realmUser),
    loadProfiles2({ user: "student" }, realmUser),
    realmUser.callFunction("getAllWellnessData"),
    loadAllSchools(realmUser),
  ]);
}

export function createStudentMaps(students) {
  return students.reduce(
    (maps, student) => {
      maps.idToEmail[student.cleverId] = student.email;
      maps.emailToName[student.email] = student.displayName ?? student.email;
      return maps;
    },
    { idToEmail: {}, emailToName: {} }
  );
}

export function getWellnessDataForDistrict(state, district) {
  // //console.log("test", state.districts);
  if (!state.districts[district]) {
    console.error(`District ${district} not found.`);
    return [];
  }
  let students = [];
  Object.values(state.districts[district]).forEach((school) => {
    Object.values(school).forEach((className) => {
      if (
        Array.isArray(className) &&
        className.length !== 0 &&
        typeof className[0] === "string"
      ) {
        // //console.log(className.length);
        // //console.log(typeof className[0]);
        students = students.concat(className);
      } else {
        Object.values(className).forEach((studentData) => {
          if (!students.includes(studentData.email)) {
            students.push(studentData.email);
          }
        });
      }
    });
  });
  // //console.log("students", students);
  return students.map((email) => state.studentData[email] || []).flat();
}
