import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  FaUser,
  FaSchool,
  FaBuilding,
  FaAppleAlt,
  FaSearch,
  FaGlobe,
  FaChevronRight,
  FaChevronDown,
} from "react-icons/fa";
import "./ExpandableSearchDropdown.css";
import { handleDistrict } from "../handlers/districtHandler";
import { handleSchool } from "../handlers/schoolHandler";
import { handleClass } from "../handlers/classHandler";
import { handleStudent } from "../handlers/studentHandler";

const BouncingDotsLoader = () => (
  <div className="bouncing-loader">
    <div></div>
    <div></div>
    <div></div>
  </div>
);

const ExpandableSearchDropdown = ({ state, userRole, updateState }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [expandedItems, setExpandedItems] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  console.log(state.districts)
  console.log(state.chosenDistrict)
  console.log(state.chosenSchool)
  const getOptions = useCallback(() => {
    if (!state || !state.districts) return [];

    switch (userRole) {
      case "123wellness":
        return [
          {
            type: "all-districts",
            name: "All Districts",
            size: Object.keys(state.districts).length,
          },
          ...Object.keys(state.districts).map((district) => ({
            type: "district",
            name: district,
            size: Object.keys(state.districts[district] || {}).length,
            schools: Object.keys(state.districts[district] || {}).map(
              (school) => ({
                type: "school",
                name: school,
                district,
                size: Object.keys(state.districts[district][school] || {})
                  .length,
              })
            ),
          })),
        ];
      case "superadmin":
        return [
          {
            type: "all-schools",
            name: "All Schools",
            size: Object.values(state.districts).reduce(
              (acc, district) => acc + Object.keys(district).length,
              0
            ),
          },
          ...Object.keys(state.districts).flatMap((district) =>
            Object.keys(state.districts[district] || {}).map((school) => ({
              type: "school",
              name: school,
              district,
              size: Object.keys(state.districts[district][school] || {}).length,
              classes: Object.keys(state.districts[district][school] || {}).map(
                (className) => ({
                  type: "class",
                  name: className,
                  school,
                  district,
                  size: (state.districts[district][school][className] || [])
                    .length,
                  students: getStudents({ name: className, school, district }),
                })
              ),
            }))
          ),
        ];
      case "admin":
      case "teacher":
        if (
          state.chosenDistrict &&
          state.districts[state.chosenDistrict] &&
          state.chosenSchool
        ) {
          return [
            {
              type: "all-classes",
              name: "All Classes",
              size: Object.keys(
                state.districts[state.chosenDistrict][state.chosenSchool] || {}
              ).length,
            },
            ...Object.keys(
              state.districts[state.chosenDistrict][state.chosenSchool] || {}
            ).map((className) => ({
              type: "class",
              name: className,
              school: state.chosenSchool,
              district: state.chosenDistrict,
              size: (
                state.districts[state.chosenDistrict][state.chosenSchool][
                  className
                ] || []
              ).length,
              students: getStudents({
                name: className,
                school: state.chosenSchool,
                district: state.chosenDistrict,
              }),
            })),
          ];
        }
        return [];
      default:
        return [];
    }
  }, [state, userRole]);

  const getStudents = useCallback(
    (classOption) => {
      if (
        !state.districts ||
        !state.districts[classOption.district] ||
        !state.districts[classOption.district][classOption.school] ||
        !state.districts[classOption.district][classOption.school][
          classOption.name
        ]
      ) {
        return [];
      }
      return state.districts[classOption.district][classOption.school][
        classOption.name
      ].map((studentEmail) => ({
        type: "student",
        name: state.studentEmailToNameMap[studentEmail] ?? studentEmail,
        email: studentEmail,
        class: classOption.name,
        school: classOption.school,
        district: classOption.district,
      }));
    },
    [state]
  );

  const handleOptionClick = (option, event) => {
    event.stopPropagation();
    setSelectedOption(option);

    let newState = { ...state };

    switch (option.type) {
      case "all-districts":
        newState = handleDistrict(state, "All Districts");
        updateState(newState);
        break;
      case "all-schools":
        newState = handleSchool(state, "All Schools");
        updateState(newState);
        break;
      case "all-classes":
        newState = handleClass(state, "All Classes");
        updateState(newState);
        break;
      case "district":
        newState = handleDistrict(state, option.name);
        updateState(newState);
        break;
      case "school":
        newState = {
          ...state,
          ...handleDistrict(state, option.district),
          ...handleSchool(state, option.name),
        };
        updateState(newState);
        break;
      case "class":
        newState = {
          ...newState,
          ...handleDistrict(state, option.district),
          ...handleSchool(state, option.school),
          ...handleClass(state, option.name),
        };
        updateState(newState);
        break;
      case "student":
        // changeDistrictFilter(option.district);
        // changeSchoolFilter(option.school);
        // changeClassFilter(option.class);
        // changeStudentFilter(option.email);
        newState = {
          ...newState,
          ...handleDistrict(state, option.district),
          ...handleSchool(state, option.school),
          ...handleClass(state, option.class),
          ...handleStudent(state, option.email),
        };
        updateState(newState);

        break;
      default:
        break;
    }

    setIsOpen(false);
  };

  const toggleExpand = useCallback((option, event) => {
    event.stopPropagation();
    setExpandedItems(prev => ({ ...prev, [option.name]: !prev[option.name] }));
  }, []);

  const searchOptions = useCallback((options, term) => {
    const searchLower = term.toLowerCase();
    const result = new Set();

    const searchRecursive = (items) => {
      items.forEach(item => {
        if (item.name.toLowerCase().includes(searchLower)) {
          result.add(item);
        }
        if (item.schools) searchRecursive(item.schools);
        if (item.classes) searchRecursive(item.classes);
        if (item.students) searchRecursive(item.students);
      });
    };

    searchRecursive(options);
    return Array.from(result);
  }, []);

  const renderOptions = useCallback(
    (options, level = 0) => {
      let sortedOptions = [...options];

      if (selectedOption) {
        const selectedOptionIndex = sortedOptions.findIndex(
          (o) => o.name === selectedOption.name
        );
        if (selectedOptionIndex !== -1) {
          const [selectedItem] = sortedOptions.splice(selectedOptionIndex, 1);
          sortedOptions.unshift(selectedItem);
        }

        if (selectedOption.school) {
          const selectedSchoolIndex = sortedOptions.findIndex(
            (o) => o.name === selectedOption.school
          );
          if (selectedSchoolIndex !== -1) {
            const [selectedSchool] = sortedOptions.splice(
              selectedSchoolIndex,
              1
            );
            sortedOptions.unshift(selectedSchool);
          }
        }
      }

      return sortedOptions.map((option, index) => {
        const hasChildren = option.schools || option.classes || option.students;
        const isExpanded =
          expandedItems[option.name] ||
          searchTerm !== "" ||
          (selectedOption &&
            (selectedOption.school === option.name ||
              selectedOption.district === option.name));
        const isSelected =
          selectedOption && selectedOption.name === option.name;

        const renderedOption = (
          <li
            key={`${option.type}-${index}-${level}`}
            onClick={(e) => handleOptionClick(option, e)}
            className={`option ${option.type} ${isSelected ? "selected" : ""}`}
          >
            {hasChildren && (
              <span
                onClick={(e) => toggleExpand(option, e)}
                className="expand-icon"
              >
                {isExpanded ? <FaChevronDown /> : <FaChevronRight />}
              </span>
            )}
            {option.type === "all-districts" && <FaGlobe className="icon" />}
            {option.type === "all-schools" && <FaGlobe className="icon" />}
            {option.type === "all-classes" && <FaGlobe className="icon" />}
            {option.type === "district" && <FaBuilding className="icon" />}
            {option.type === "school" && <FaSchool className="icon" />}
            {option.type === "class" && <FaAppleAlt className="icon" />}
            {option.type === "student" && <FaUser className="icon" />}
            {option.name}
            {option.type === "all-districts" && ` (${option.size} districts)`}
            {option.type === "all-schools" && ` (${option.size} schools)`}
            {option.type === "all-classes" && ` (${option.size} classes)`}
            {option.type === "district" && ` (${option.size} schools)`}
            {option.type === "school" && ` (${option.size} classes)`}
            {option.type === "class" && ` (${option.size} students)`}
          </li>
        );

        if (hasChildren && isExpanded) {
          return [
            renderedOption,
            <ul
              key={`children-${option.type}-${index}-${level}`}
              className="nested-options"
            >
              {renderOptions(
                option.schools || option.classes || option.students || [],
                level + 1
              )}
            </ul>,
          ];
        }

        return renderedOption;
      });
    },
    [selectedOption, expandedItems, searchTerm, handleOptionClick, toggleExpand]
  );

  const filteredOptions = useMemo(() => {
    const options = getOptions();
    if (!searchTerm) return options;
    return searchOptions(options, searchTerm);
  }, [getOptions, searchTerm, searchOptions]);

  const getPlaceholder = useCallback(() => {
    if (selectedOption) {
      let suffix = "";
      switch (selectedOption.type) {
        case "all-districts":
          suffix = ` (${selectedOption.size} districts)`;
          break;
        case "all-schools":
          suffix = ` (${selectedOption.size} schools)`;
          break;
        case "all-classes":
          suffix = ` (${selectedOption.size} classes)`;
          break;
        case "district":
          suffix = ` (${selectedOption.size} schools)`;
          break;
        case "school":
          suffix = ` (${selectedOption.size} classes)`;
          break;
        case "class":
          suffix = ` (${selectedOption.size} students)`;
          break;
      }
      return selectedOption.name + suffix;
    }
    return "Search...";
  }, [selectedOption]);

  const getIcon = useCallback(() => {
    if (selectedOption) {
      switch (selectedOption.type) {
        case "all-districts":
        case "all-schools":
        case "all-classes":
          return <FaGlobe className="selected-icon" />;
        case "district":
          return <FaBuilding className="selected-icon" />;
        case "school":
          return <FaSchool className="selected-icon" />;
        case "class":
          return <FaAppleAlt className="selected-icon" />;
        case "student":
          return <FaUser className="selected-icon" />;
        default:
          return null;
      }
    }
    return null;
  }, [selectedOption]);

  const handleSearch = (value) => {
    setSearchTerm(value);
    setIsLoading(true);
    if (selectedOption) {
      setSelectedOption(null);
    }
    setIsOpen(true);

    // Simulate async search
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  return (
    <div className="expandable-search-dropdown" ref={dropdownRef}>
      <div className={`search-input-container ${selectedOption ? "selected" : ""}`}>
        {getIcon()}
        <input
          type="text"
          className="search-input filterprofiledatasuper"
          placeholder="Search..."
          value={selectedOption ? getPlaceholder() : searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
          onFocus={() => setIsOpen(true)}
        />
      </div>
      {isOpen && (
        <ul className="options-list">
          {isLoading ? (
            <li className="loading">
              <BouncingDotsLoader />
            </li>
          ) : filteredOptions.length > 0 ? (
            renderOptions(filteredOptions)
          ) : (
            <li className="no-results">
              <div className="no-results-icon">
                <FaSearch />
              </div>
              <p>No data found</p>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default ExpandableSearchDropdown;
