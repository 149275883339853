import React, { useState } from "react";
import './BarGraph.css';
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import { useMediaQuery } from 'react-responsive';

const BarGraph = ({ barGraphData, CustomTooltip, title }) => {
    const [tooltipData, setTooltipData] = useState(null);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [barHovered, setBarHovered] = useState(false);

    const handleBarClick = (barGraphData) => {
        setTooltipData(barGraphData);
        setTooltipVisible(!tooltipVisible);
    };

    const isMobile = useMediaQuery({ maxWidth: 600 });

    const getAbbreviation = (emotion) => {
        const abbreviations = {
            "sad": "Sad",
            "tired": "Tird",
            "lonely": "Lnly",
            "happy": "Hpy",
            "grateful": "Grat",
            "calm": "Calm",
            "stressed": "Strs",
            "scared": "Scrd",
            "angry": "Ang"
        };
        return abbreviations[emotion] || emotion.substring(0, 4);
    };

    const CustomBarLabel = ({ x, y, width, height, value, fill, name }) => {
        const isTwoBarChart = barGraphData.some(data => 'compare' in data);
        if (!isTwoBarChart) {
            return (
                <text
                  x={x + width / 2}
                  y={y + height / 2}
                  fill="#ffffff" // Adjust color as needed for contrast
                  textAnchor="middle"
                  dominantBaseline="central"
                  transform={`rotate(-90, ${x + width / 2}, ${y + height / 2})`}
                  fontSize="12px" // Adjust font size as needed
                >
                  {name}
                </text>
              );
        }
    };

    const emotionEmojiImgMapping = {
        "sad": { "alt": "sad-icon", "src": "images/emotions/sad.png" },
        "tired": { "alt": "tired-icon", "src": "images/emotions/tired.png" },
        "lonely": { "alt": "lonely-icon", "src": "images/emotions/lonely.png" },
        "happy": { "alt": "happy-icon", "src": "images/emotions/happy.png" },
        "grateful": { "alt": "grateful-icon", "src": "images/emotions/grateful.png" },
        "calm": { "alt": "calm-icon", "src": "images/emotions/calm.png" },
        "stressed": { "alt": "stressed-icon", "src": "images/emotions/stressed.png" },
        "scared": { "alt": "scared-icon", "src": "images/emotions/scared.png" },
        "angry": { "alt": "angry-icon", "src": "images/emotions/angry.png" }
    };

    const renderCustomAxisTick = ({ x, y, payload }) => {
        let img_src = emotionEmojiImgMapping[payload.value]["src"];
        let img_alt = emotionEmojiImgMapping[payload.value]["alt"];
        let displayText = isMobile ? getAbbreviation(payload.value) : payload.value;

        return (
            <g transform={`translate(${x},${y})`}>
                <svg x={-17} y={0} width={50} height={50} overflow={'visible'} xmlns="http://www.w3.org/2000/svg">
                    <image
                        x={0}
                        y={0}
                        width="100%"
                        height="100%"
                        alt={img_alt}
                        href={img_src}
                        className="filterlabelsimg"
                    />
                </svg>
                {isMobile ? (
                                    <text className='barGraphLabel' x={-10} y={-10} dy={60} textAnchor="middle" fill="#666">
                                    {displayText}
                                </text>
                ) :(
                    <text className='barGraphLabel' x={0} y={0} dy={60} textAnchor="middle" fill="#666">
                    {displayText}
                </text>
                )
                    
                }

            </g>
        );
    };

    const barColors = ["#5b9cd6", "#4cc68d", "#bd92e4"];
    const barColorsGray = ["#D3D3D3"];

    const hasCompareData = barGraphData.some(data => 'compare' in data);

    return (
        <div className="chart-container">
            {title && <div className="chart-title">{title}</div>}
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={barGraphData}
                    onClick={(barGraphData) => handleBarClick(barGraphData)}
                    margin={{ top: 20, right: 20, left: 20, bottom: 50 }}
                >
                    <Tooltip
                        // cursor={false}
                        cursor={{ fill: "lightgray", fillOpacity: "30%" }}
                        wrapperStyle={{ pointerEvents: "auto"}}
                        trigger='click'
                        content={<CustomTooltip show={tooltipVisible} payload={tooltipData?.payload} label={tooltipData?.label} />}                    
                    />                   
                    <YAxis hide={true} />

                    <Bar maxBarSize={50} dataKey={hasCompareData ? "current" : "count"}
                     label={isMobile ? <CustomBarLabel /> : null}>
                        
                        {barGraphData.map((entry, index) => (
                            <Cell key={index} fill={barColors[Math.floor(index / 3)]} />
                        ))}
                        onMouseEnter={() => setBarHovered(false)}
                        onMouseLeave={() => setBarHovered(false)}
                    </Bar>
                    {hasCompareData && (
                        <Bar maxBarSize={50} dataKey="compare">
                            {barGraphData.map((entry, index) => (
                                <Cell key={index} fill={barColorsGray[0]} />
                            ))}
                        </Bar>
                    )}
                    <XAxis axisLine={false} tickLine={false} interval={0} tick={renderCustomAxisTick} dataKey="name" minTickGap={-2} dy={10} offset={0} />
                </BarChart>
                {tooltipVisible && tooltipData && (
                    <div
                        className="custom-tooltip"
                    >
                        <CustomTooltip show={tooltipVisible} payload={tooltipData.payload} label={tooltipData.label} />
                    </div>
                )}
            </ResponsiveContainer>
        </div>
    );
};

export default BarGraph;