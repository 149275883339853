
export const timeframeOptions = [
    { value: '10 minutes', display: 'Last 10 minutes' },
    { value: '1 hour', display: 'Last hour' },
    { value: '2 hours', display: 'Last 2 hours' },
    { value: '24 hours', display: 'Last 24 hours' },
    { value: 'week', display: 'Last 7 days' },
    { value: 'month', display: 'Last 30 days' },
    { value: '3 months', display: 'Last 3 months' },
    { value: '6 months', display: 'Last 6 months' },
    { value: 'year', display: 'Last 12 months' }
  ];
  
  export const compareTimeframeOptions = timeframeOptions.map(option => ({
    ...option,
    display: `Previous ${option.display.toLowerCase().replace('last ', '')}`
  }));
  