import {
  loadAllDistricts,
  loadAllSchools,
  loadProfiles2,
} from "../../realm/graphqlQueries";
import { runPaginatedQuery } from "../../realm/runPaginatedQuery";
import { createWellnessMap, processData } from "./utils/dataProcessors";

export async function get123WellnessData(state, realmUser) {
  try {
    const [districtsRaw, allStudents, allWellnessDataBulk, allSchools] =
      await Promise.all([
        loadAllDistricts(realmUser),
        loadProfiles2({ user: "student" }, realmUser),
        runPaginatedQuery(realmUser, "wellness", null, null, null, { date: 1 }),
        loadAllSchools(realmUser),
      ]);

    const wellnessMapData = createWellnessMap(allWellnessDataBulk);

    const {
      districts,
      studentData,
      allWellnessPoints,
      currentDictionary,
      compareDictionary,
      recordCounter,
      anonymousStudentMap,
    } = processData(
      districtsRaw.districts,
      allSchools.schools,
      allStudents.users,
      wellnessMapData,
      state,
      true
    );

    console.log(
      "Users practiced a total of ",
      recordCounter,
      "all students: ",
      allStudents.users.length
    );

    return {
      districts,
      allWellnessPoints,
      allStudents: allStudents.users,
      studentData,
      currentDictionary,
      compareDictionary,
      loading: false,
      counter: recordCounter,
    };
  } catch (error) {
    console.error("Error in get123WellnessData:", error);
    throw error;
  }
}

// import {
//   loadAllDistricts,
//   loadAllSchools,
//   loadProfiles2,
// } from "../../realm/graphqlQueries";
// import moment from "moment";

// function createWellnessMap(allWellnessDataBulk) {
//   const wellnessMap = {};
//   let recordCounter = 0;
//   allWellnessDataBulk.forEach((record) => {
//     const userID = record.userID;
//     if (!wellnessMap[userID]) {
//       wellnessMap[userID] = [];
//     }
//     wellnessMap[userID].push(record);
//     recordCounter++;
//   });
//   return { wellnessMap, recordCounter };
// }

// function processData(
//   districtsRaw,
//   allSchools,
//   allStudents,
//   wellnessMapData,
//   currentDate,
//   compareDate
// ) {
//   const districts = {};
//   const studentData = {};
//   const allWellnessPoints = [];
//   const currentDictionary = initEmotionDictionary();
//   const compareDictionary = initEmotionDictionary();
//   const { wellnessMap, recordCounter } = wellnessMapData;

//   districtsRaw.forEach((districtItem) => {
//     console.log(districtItem.name);
//     const schools = allSchools.filter((s) =>
//       [districtItem.name, districtItem.cleverId].includes(s.district)
//     );

//     // console.log(JSON.stringify(schools));

//     const districtData = {};
//     schools.forEach((school) => {
//       districtData[school.name] = {};
//       districtData[school.name]["class"] = []
//       const studentsInSchool = allStudents.filter(
//         (student) =>
//           student.school === school.name ||
//           student.schools?.includes(school.name) ||
//           student.schools?.includes(school.cleverId)
//       );

//       studentsInSchool.forEach((student) => {
//         if (student.email) {
//           districtData[school.name]["class"].push(student.email);
//           studentData[student.email] = wellnessMap[student._id] || [];
//           studentData[student.email].forEach((datapoint) => {
//             allWellnessPoints.push(datapoint);
//             updateEmotionDictionaries(
//               datapoint,
//               currentDate,
//               compareDate,
//               currentDictionary,
//               compareDictionary
//             );
//           });
//         }
//       });
//     });

//     districts[districtItem.name] = districtData;
//   });

//   return {
//     districts,
//     studentData,
//     allWellnessPoints,
//     currentDictionary,
//     compareDictionary,
//     recordCounter,
//   };
// }

// function initEmotionDictionary() {
//   return {
//     sad: 0,
//     tired: 0,
//     lonely: 0,
//     happy: 0,
//     grateful: 0,
//     calm: 0,
//     stressed: 0,
//     scared: 0,
//     angry: 0,
//   };
// }

// function updateEmotionDictionaries(
//   datapoint,
//   currentDate,
//   compareDate,
//   currentDictionary,
//   compareDictionary
// ) {
//   const datapointTimestamp = new Date(datapoint.date).valueOf();
//   if (datapointTimestamp >= currentDate.valueOf()) {
//     currentDictionary[datapoint.emotion]++;
//   } else if (datapointTimestamp >= compareDate.valueOf()) {
//     compareDictionary[datapoint.emotion]++;
//   }
// }

// export async function get123WellnessData(state, realmUser) {
//   const currentDate = moment().subtract(state.filterAmountCurrent);
//   const compareDate = moment().subtract(
//     state.filterAmountCompare + state.filterAmountCurrent
//   );

//   let [districtsRaw, allStudents, allWellnessDataBulk, allSchools] =
//     await Promise.all([
//       loadAllDistricts(realmUser),
//       loadProfiles2({ user: "student" }, realmUser),
//       realmUser.callFunction("getAllWellnessData"),
//       loadAllSchools(realmUser),
//     ]);

//   const wellnessMap = createWellnessMap(allWellnessDataBulk);
//   const {
//     districts,
//     studentData,
//     allWellnessPoints,
//     currentDictionary,
//     compareDictionary,
//     recordCounter,
//   } = processData(
//     districtsRaw.districts,
//     allSchools.schools,
//     allStudents.users,
//     wellnessMap,
//     currentDate,
//     compareDate
//   );

//   console.log(
//     "Users practiced a total of ",
//     recordCounter,
//     "all students: ",
//     allStudents.users.length
//   );
//   return {
//     districts,
//     allWellnessPoints,
//     allStudents: allStudents.users,
//     studentData,
//     currentDictionary,
//     compareDictionary,
//     loading: false,
//     counter: 0,
//   };
// }
