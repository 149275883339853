import { useEffect, useState, useContext } from "react";
import { authentication, Signout } from "../../firebase";
import "./OptionsScreen.css";
import { Switch, ThemeProvider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { UserContext } from "../../realm/user.context";
import { loadProfile, updateProfile } from "../../realm/graphqlQueries";
import Spacer from "../../components/Spacer";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";

const DisplayNameButton = ({ email, setName }) => {
  const [onMobile, setMobile] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [hoveringSubmit, setHoveringSubmit] = useState(false);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [changingName, setChangingName] = useState(false);
  const [updating, setUpdating] = useState(false);
  const { realmUser } = useContext(UserContext);

  const canSubmit = firstName !== "" && lastName !== "";

  useEffect(() => {
    const handleResize = () => setMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div
        onClick={() => setChangingName(true)}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          color: "#fff",
          padding: "2vmin",
          fontSize: "3vmin",
          marginTop: "5vmin",
          fontWeight: "bold",
          textAlign: "center",
          alignSelf: "center",
          borderRadius: "5px",
          backgroundColor: "#4cc68d",
          cursor: hovering ? "pointer" : "default",
        }}
      >
        Click Here to Change Your Display Name
      </div>
      <div
        onClick={() => setChangingName(false)}
        style={{
          top: 0,
          left: 0,
          display: "flex",
          position: "fixed",
          alignItems: "center",
          justifyContent: "center",
          transition: "opacity 0.5s",
          opacity: changingName ? 1 : 0,
          width: changingName ? "100vw" : 0,
          height: changingName ? "100vh" : 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        {changingName && (
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              width: "50vw",
              display: "flex",
              padding: "2vmin",
              borderRadius: "5px",
              alignItems: "center",
              flexDirection: "column",
              backgroundColor: "#fff",
              marginLeft: onMobile ? 0 : "20vw",
            }}
          >
            <p
              style={{
                margin: 0,
                color: "#4cc68d",
                fontSize: "5vmin",
                fontWeight: "bold",
              }}
            >
              Update Name
            </p>
            <Spacer height="2vmin" />
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <p style={{ margin: 0, color: "#4cc68d", fontSize: "3vmin" }}>
                First Name
              </p>
              <div style={{ width: "4vmin" }} />
              <input
                onChange={(e) => setFirstName(e.target.value)}
                style={{
                  flex: 1,
                  color: "#4cc68d",
                  padding: "1vmin",
                  fontSize: "3vmin",
                  borderRadius: "5px",
                  borderStyle: "solid",
                  borderColor: "#4cc68d",
                }}
              />
            </div>
            <Spacer height="2vmin" />
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <p style={{ margin: 0, color: "#4cc68d", fontSize: "3vmin" }}>
                Last Name
              </p>
              <div style={{ width: "4vmin" }} />
              <input
                onChange={(e) => setLastName(e.target.value)}
                style={{
                  flex: 1,
                  color: "#4cc68d",
                  padding: "1vmin",
                  fontSize: "3vmin",
                  borderRadius: "5px",
                  borderStyle: "solid",
                  borderColor: "#4cc68d",
                }}
              />
            </div>
            <Spacer height="2vmin" />
            <div
              onClick={async () => {
                if (!updating && changingName) {
                  setUpdating(true);
                  try {
                    await updateProfile(email, realmUser, {
                      displayName: `${firstName} ${lastName}`,
                    });
                    setName(`${firstName} ${lastName}`);
                    setFirstName("");
                    setLastName("");
                  } finally {
                    setUpdating(false);
                    setChangingName(false);
                  }
                }
              }}
              onMouseEnter={() => setHoveringSubmit(true)}
              onMouseLeave={() => setHoveringSubmit(false)}
              style={{
                color: "#fff",
                padding: "1vmin",
                fontSize: "2vmin",
                borderRadius: "5px",
                backgroundColor: "#4cc68d",
                filter: `grayscale(${canSubmit ? 0 : 1})`,
                cursor: canSubmit && hoveringSubmit ? "pointer" : "default",
              }}
            >
              Submit
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const OptionsScreen = (props) => {
  const [name, setName] = useState("");
  const [img, setImg] = useState("");
  const [email, setEmail] = useState("");
  const [switchVal, setSwitchVal] = useState(true);
  const [wellnessTimer, setWellnessTimer] = useState("1 minute");
  const [adultImagesSetting, setAdultImagesSetting] = useState(props.user.user.adultActionImages ? true : false);
  const [usesCastle, setusesCastle] = useState(props.user.user.usesCastle ? true : false);
  const [voice, setVoice] = useState("english female");
  const [onMobile, setOnMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setOnMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { realmUser, logOutRealmUser } = useContext(UserContext);

  const theme = createTheme({
    palette: {
      custom: {
        main: "#bd92e4",
      },
    },
  });

  useEffect(() => {
    // populate with firebase
    if (authentication.currentUser) {
      setName(
        props.user.user.displayName ?? authentication.currentUser.displayName
      );
      setImg(authentication.currentUser.photoURL);
      setEmail(props.user.user.email);
    } else {
      setName(props.user.user.displayName);
      setImg("");
      setEmail(props.user.user.email);
    }
    setWellnessTimer(props.user.user.wellnesstimer);
    // setAdultImagesSetting(props.user.user.adultImagesSetting)
    setSwitchVal(props.user.user.dataUse);
    // console.log(props.user.user.dataUse)
    if (props.user.user.voice) {
      setVoice(props.user.user.voice);
    }
  }, [props.user.user.wellnesstimer, props.user.user.dataUse]);

  const navigate = useNavigate();
  function logOutAndNav() {
    Signout();
    logOutRealmUser();
    navigate("/");
  }
  async function handleSwitch() {
    const email = authentication.currentUser?.email ?? props.user.user.email;
    const newSwitchValue = !switchVal
    await updateProfile(email, realmUser, {
      dataUse: newSwitchValue,
    });
    setSwitchVal(newSwitchValue);
    let tmpProps = props.user;
    tmpProps.user.dataUse = !switchVal; //ANTI PATTERN ALERT
    props.setUser(tmpProps);
    // console.log(props.user.user, tmpProps.user);
  }
  async function handleWellnessTimerDropDown(value) {
    setWellnessTimer(value);
    await updateProfile(props.user.user.email, realmUser, {
      wellnesstimer: value,
    });
    let tmpProps = props.user;
    tmpProps.user.wellnesstimer = value;
    props.setUser(tmpProps);
  }

  async function handleAdultImagesDropDown(value) {
    var setValue = false
    if (value.toLowerCase() === 'true'){
      setValue = true
    } else {
      setValue = false
    }
    setAdultImagesSetting(setValue)
    await updateProfile(props.user.user.email, realmUser, {
      adultActionImages: setValue,
    });
    let tmpProps = props.user;
    tmpProps.user.adultActionImages = setValue;
    props.setUser(tmpProps);
  }

  async function handleCastleDropdown(value) {
    var setValue = false
    if (value.toLowerCase() === 'true'){
      setValue = true
    } else {
      setValue = false
    }
    setusesCastle(setValue)
    await updateProfile(props.user.user.email, realmUser, {
      usesCastle: setValue,
    });
    let tmpProps = props.user;
    tmpProps.user.usesCastle = setValue;
    props.setUser(tmpProps);
  }

  async function handleVoiceDropdown(value) {
    setVoice(value);
    const realmResp = await updateProfile(
      props.user.user.email,
      realmUser,
      { voice: value }
    );
    let tmpProps = props.user;
    tmpProps.user.voice = value;
    props.setUser(tmpProps);
  }

  return (
    <div className="optionsoutercolumn">
      <div className="optionsprofilerow">
        {img ? (
          <img className="optionsimg" src={img} alt="...loading"></img>
        ) : (
          <SentimentSatisfiedAltIcon
            style={{
              fontSize: onMobile ? "7.5vmax" : "5vmax",
              color: "#4cc68d",
              left: onMobile ? "-10vmin" : "-5vmin",
            }}
          />
        )}
        <div>
          <div className="fullname">{name}</div>
          <div className="optionsemail">{email}</div>
        </div>
      </div>
      {props.user.user.user !== "student" && (
        <DisplayNameButton email={props.user.user.email} setName={setName} />
      )}
      <div className="settingsPageHeaderText">Wellness timer</div>
      <div className="settingsPageExplanationTextMarginTop">
        Change the amount of time that you practice wellness during step 3.
      </div>
      <select
        className="optionselect"
        value={wellnessTimer}
        onChange={(e) => handleWellnessTimerDropDown(e.target.value)}
      >
        <option value={"10 seconds"}>10 seconds</option>
        <option value={"20 seconds"}>20 seconds</option>
        <option value={"30 seconds"}>30 seconds</option>
        <option value={"1 minute"}>1 minute</option>
        <option value={"2 minutes"}>2 minutes</option>
      </select>{" "}
      <div className="settingsPageHeaderText">Wellness Voiceover</div>
      <div className="settingsPageExplanationTextMarginTop">
        Change the voice that guides you through the Wellness Practice.
      </div>
      <select
        className="optionselect"
        value={voice}
        onChange={(e) => handleVoiceDropdown(e.target.value)}
      >
        <option value={"english female"}>Lina (English)</option>
        <option value={"english male"}>Drew (English)</option>
        <option value={"spanish female"}>Lina (Spanish)</option>
      </select>{" "}
      <div className="settingsPageHeaderText">Data Privacy</div>
      <div className="switchrow">
        <div className="settingsPageExplanationText">
          Make my individual data visible to my teacher(s) and administrator(s)
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ThemeProvider theme={theme}>
            <Switch
              value={switchVal}
              color={"custom"}
              checked={switchVal}
              onClick={() => handleSwitch()}
              className="switchstyle"
            />
          </ThemeProvider>
          {switchVal ? <div>On</div> : <div>Off</div>}
        </div>
      </div>
      <div className="settingsPageHeaderText">Show Adult Wellness Images</div>
      <div className="settingsPageExplanationTextMarginTop">Make my wellness practice images illustrations of adult wellness practicers</div>
      <select
        className="optionselect"
        value={adultImagesSetting}
        onChange={(e) => handleAdultImagesDropDown(e.target.value)}
      >
        <option value={false}>No</option>
        <option value={true}>Yes</option>
      </select>{" "}
      <div className="settingsPageHeaderText">Use the Castle?</div>
      <div className="settingsPageExplanationTextMarginTop">Use the castle as the wellness progress tracker on my Home Screen</div>
      <select
        className="optionselect"
        value={usesCastle}
        onChange={(e) => handleCastleDropdown(e.target.value)}
      >
        <option value={false}>No</option>
        <option value={true}>Yes</option>
      </select>{" "}
      <div className="account">Account</div>
      <div className="buttonrow">
        {/* <button class="switchuser">Switch User</button> */}
        <button
          onClick={logOutAndNav}
          className="logout wellnessButtons"
          style={{
            backgroundColor: "#C2353B",
            color: "white",
            fontWeight: "bold",
            marginBottom: "4vh"
          }}
        >
          Log Out
        </button>
      </div>
    </div>
  );
};

export default OptionsScreen;
